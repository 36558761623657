<template>
  <div class="card p-shadow-6" :style="{ position: 'relative' }">
    <HomeTasksListUnrealized
      v-if="tasksToComplete == null || tasksToComplete > 0"
      v-on:getTasksToComplete="tasksToCompleteChanged"
    />
    <Button
      label="A minha agenda"
      class="p-button-text p-button-lg"
      @click="$router.push('hours')"
    />
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-12 p-ml-3">
        <b>Total de horas: </b> {{ totalWorkHoursFormated }}
      </div>
    </div>
    <Loading :active.sync="isLoading" :is-full-page="false"></Loading>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog />
    <Dialog
      id="'confirm-end-service'"
      :visible.sync="endServiceModal"
      :style="{ width: $isMobile() ? '100vw' : '60vw' }"
      :modal="true"
      :header="'Terminar serviço - Instalação do cliente'"
    >
      <div class="confirmation-content">
        <Message
          v-if="endServicePatNum % 10 == 0"
          severity="error"
          :closable="false"
        >
          Atenção que existe um formulário de satisfação a preencher junto do
          cliente antes de terminar o serviço
        </Message>
        <Message v-if="hasInstallationSheet" severity="error" :closable="false">
          Não te esqueças de preencher a folha de instalação
        </Message>
        <i class="pi pi-question-circle p-mr-3" style="font-size: 2rem" />
        <span>{{ dialogMessage }}</span>
      </div>
      <template #footer>
        <Button
          v-if="hasInstallationSheet"
          label="Ir para Folha de Instalação"
          icon="pi pi-book"
          @click="goToInstallationSheet"
          class="p-button-info p-button p-component"
        />
        <Button
          v-if="endServicePatNum % 10 == 0"
          label="Formulário de Satisfação"
          icon="pi pi-check-circle"
          @click="goSatisfactionForm"
          class="p-button-info p-button p-component"
        />
        <Button
          label="Não"
          icon="pi pi-times"
          @click="closeModalEndService"
          class="p-button-danger p-button p-component"
          autofocus
        />
        <Button
          label="Sim"
          icon="pi pi-check"
          @click="endService(endServiceTaskId)"
          class="p-button-success p-button p-component"
        />
      </template>
    </Dialog>

    <Dialog
      id="'dialog-support-home'"
      :visible.sync="showServiceForm"
      :style="{ width: $isMobile() ? '100vw' : '60vw' }"
      :modal="true"
      :header="'Terminar serviço'"
    >
      <ServiceForm
        :service="service"
        v-on:submitServiceForm="serviceFormSubmited"
        v-on:cancelServiceForm="serviceFormClose"
      />
    </Dialog>

    <Dialog
      id="'dialog-survey-home'"
      :visible.sync="showSurveyForm"
      :style="{ width: $isMobile() ? '100vw' : '60vw' }"
      :modal="true"
      :header="'Servey Info'"
    >
      <SurveyForm
        :service="service"
        v-on:submitSurveyForm="surveyFormSubmited"
        v-on:cancelSurveyForm="surveyFormClose"
      />
    </Dialog>

    <Dialog
      id="'calendarGoTo'"
      :visible.sync="calendarGoTo"
      :style="{ width: $isMobile() ? '100vw' : '35vw' }"
      :modal="true"
      :header="'Ir para dia'"
    >
      <div class="p-col-12 p-text-center">
        <v-calendar @dayclick="goDate" />
      </div>
    </Dialog>

    <Dialog
      id="'address'"
      :visible.sync="showInfo"
      :style="{ width: $isMobile() ? '100vw' : '35vw' }"
      :modal="true"
      :header="'Informação do Serviço'"
      @hide="hideAdrress"
    >
      <div class="p-col-12">
        <div>
          <h5>{{ moreInfo.entity }}</h5>
        </div>
        <Message
          v-if="moreInfo.patNunber % 10 == 0"
          severity="warn"
          :closable="false"
        >
          No final do serviço preencher o inquérito de satisfação junto do
          cliente
        </Message>

        <div>
          <b>Pat:</b>
          {{ moreInfo.patNunber }}
        </div>
        <div v-if="moreInfo.patServiceType">
          <b>Tipo de Serviço:</b>
          {{ moreInfo.patServiceType }}
        </div>
        <div v-if="moreInfo.patTitle">
          <b>Título: </b>
          {{ moreInfo.patTitle }}
        </div>
        <div v-if="moreInfo.patProblem">
          <b>Problema: </b>
          {{ moreInfo.patProblem }}
        </div>
        <div v-if="moreInfo.patNote">
          <b>Nota: </b>
          {{ moreInfo.patNote }}
        </div>
        <h4>Morada:</h4>
        <div>
          {{ moreInfo.address }} <br />
          {{ moreInfo.zipCode }} <br />
          {{ moreInfo.local }}
        </div>
        <div class="p-mt-1">
          <Button
            label="Ver no mapa"
            class="p-button-success"
            icon="pi pi-map-marker"
            @click="goToAdrress"
          />
        </div>

        <div v-if="moreInfo.hasInstallationSheet" class="p-mt-4">
          <h4>Folha de Instalação</h4>
          <Button
            label="Ver folha de instalação"
            class="p-button-success"
            icon="pi pi-book"
            @click="goToInstallationSheet"
          />
        </div>
        <div class="p-mt-4">
          <Button
            label="Ver Informação do Pat"
            class="p-button-primary"
            icon="pi pi-eye"
            @click="viewPatInfo(moreInfo.patNunber)"
          />
        </div>
      </div>

      <template #footer>
        <Button
          label="Fechar"
          class="p-button-danger"
          icon="pi pi-times"
          @click="hideAdrress"
        />
      </template>
    </Dialog>

    <FullCalendar ref="fullCalendar" :options="calendarOptions">
      <template v-slot:eventContent="arg">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-8 p-md-8">
            <div v-if="arg.event._def.extendedProps.isHoliday">
              <div><b>Feriado:</b> {{ arg.event.title }}</div>
            </div>
            <div v-else-if="arg.event._def.extendedProps.inVacation">
              <div style="color: #000000">
                <b>Férias</b> {{ arg.event.title }}
              </div>
            </div>
            <div v-else-if="arg.event._def.extendedProps.missed">
              <div><b>Falta:</b> {{ arg.event.title }}</div>
            </div>
            <div v-else-if="arg.event._def.extendedProps.taskInfo.isBooking">
              <div v-if="arg.event._def.extendedProps.taskInfo.type == 1">
                <b>Reserva</b>
              </div>
              <div v-if="arg.event._def.extendedProps.taskInfo.type == 2">
                <b>Indisponivel</b>
              </div>
              <div v-if="arg.event._def.extendedProps.taskInfo.note != ''">
                <b>Nota: </b>
                {{ arg.event._def.extendedProps.taskInfo.note }}
              </div>
            </div>
            <div v-else>
              <div class="p-text-right"></div>
              <div
                v-if="arg.event._def.extendedProps.taskInfo.entity_name != ''"
              >
                <b>{{ arg.event._def.extendedProps.taskInfo.entity_name }}</b>
              </div>
              <div
                v-if="
                  arg.event._def.extendedProps.taskInfo.pat_service_type != ''
                "
              >
                <b>Tipo de Serviço: </b>
                {{ arg.event._def.extendedProps.taskInfo.pat_service_type }}
              </div>
              <div>
                <b>Pat:</b>
                {{ arg.event._def.extendedProps.taskInfo.pat_number }}
              </div>
              <div v-if="arg.event._def.extendedProps.taskInfo.pat_title != ''">
                <b>Título: </b>
                {{ arg.event._def.extendedProps.taskInfo.pat_title }}
              </div>
              <div v-if="arg.event._def.extendedProps.taskInfo.note != ''">
                <b>Nota: </b>
                {{ arg.event._def.extendedProps.taskInfo.note }}
              </div>
              <div v-if="arg.event._def.extendedProps.taskInfo.running">
                <b>Estado: </b>
                <span
                  v-if="
                    inWork &&
                    arg.event._def.extendedProps.taskInfo.service_start == '' &&
                    arg.event._def.extendedProps.taskInfo.running
                  "
                  style="color: #ff4910"
                >
                  Em deslocação
                </span>
                <span
                  v-else-if="
                    inWork &&
                    arg.event._def.extendedProps.taskInfo.service_start != '' &&
                    arg.event._def.extendedProps.taskInfo.service_end == '' &&
                    arg.event._def.extendedProps.taskInfo.running
                  "
                  style="color: #ff4910"
                >
                  Em serviço
                </span>
                <span
                  v-else-if="
                    inWork &&
                    arg.event._def.extendedProps.taskInfo.service_start != '' &&
                    arg.event._def.extendedProps.taskInfo.running &&
                    arg.event._def.extendedProps.taskInfo.service_end != ''
                  "
                  style="color: #ff4910"
                >
                  Serviço terminado. Em delocação.
                </span>
              </div>
            </div>
          </div>
          <div
            v-if="!arg.event._def.extendedProps.taskInfo.isBooking"
            class="p-fluid p-formgrid p-grid p-col-4 p-md-4"
          >
            <div class="p-field p-col-10 p-md-6">
              <Button
                icon="pi pi-info"
                class="p-button-rounded p-button-primary"
                v-tooltip="'Mais Informação'"
                @click="showTaskInfo(arg.event.id)"
              />
            </div>
            <div
              v-if="
                arg.event._def.extendedProps.taskInfo.is_today &&
                !arg.event._def.extendedProps.taskInfo.finished
              "
              class="p-field p-col-10 p-md-6"
            >
              <Button
                v-if="!inWork"
                v-tooltip="'Iniciar deslocação'"
                icon="pi pi-play"
                class="p-button-rounded p-button-success"
                @click="confirmStartTask(arg.event.id)"
              >
                <!-- <span class="play">&#9658;</span> -->
              </Button>
              <Button
                v-if="
                  inWork &&
                  arg.event._def.extendedProps.taskInfo.service_start == '' &&
                  arg.event._def.extendedProps.taskInfo.running
                "
                v-tooltip="'Iniciar Serviço'"
                icon="pi pi-users"
                class="p-button-rounded p-button-success"
                @click="confirmStartService(arg.event.id)"
              />
              <Button
                v-if="
                  inWork &&
                  arg.event._def.extendedProps.taskInfo.service_start != '' &&
                  arg.event._def.extendedProps.taskInfo.service_end == '' &&
                  arg.event._def.extendedProps.taskInfo.running
                "
                v-tooltip="'Terminar Serviço'"
                icon="pi pi-users"
                class="p-button-rounded p-button-danger"
                @click="confirmEndService(arg.event.id)"
              />
              <Button
                v-if="
                  inWork &&
                  arg.event._def.extendedProps.taskInfo.service_start != '' &&
                  arg.event._def.extendedProps.taskInfo.running &&
                  arg.event._def.extendedProps.taskInfo.service_end != ''
                "
                v-tooltip="'Terminar deslocação'"
                icon="pi pi-stop"
                class="p-button-rounded p-button-danger"
                @click="confirmEndTask(arg.event.id)"
              >
                <!-- <span class="stop"></span> -->
              </Button>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:dayHeaderContent="arg">
        <span class="p-md-10 p-text-left">
          {{ arg.text }} - {{ new Date(arg.date).getDate() }}/{{
            new Date(arg.date).getMonth() + 1
          }}/{{ new Date(arg.date).getFullYear() }}
        </span>
      </template>
    </FullCalendar>
  </div>
</template>

<script>
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import ptLocale from "@fullcalendar/core/locales/pt";
import {
  getOnlyDate,
  hexToRGB,
  isToday,
  formatMinutes,
} from "../helpers/helpers";
import taskService from "../services/task.service";
import supportService from "../services/support.service";
import ServiceForm from "./HomeTasksListForm";
import SurveyForm from "./HomeSurveyForm";
import moment from "moment";

import loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import HomeTasksListUnrealized from "./HomeTasksListUnrealized";

export default {
  name: "hours",
  components: {
    Loading: loading,
    ServiceForm,
    HomeTasksListUnrealized,
    SurveyForm,
  },
  data() {
    return {
      service: null,
      showServiceForm: false,
      userId: null,
      calendarApi: null,
      calendarOptions: null,
      isLoading: true,
      inWork: false,
      totalWorkMinutes: 0,
      totalWorkHoursFormated: "00:00",
      calendarGoTo: false,
      showInfo: false,
      showSatisfactionsFormInfo: false,
      moreInfo: {
        entity: null,
        patNunber: null,
        patTitle: null,
        patNote: null,
        patProblem: null,
        address: null,
        zipCode: null,
        local: null,
        hasInstallationSheet: null,
        taskId: null,
        patServiceType: null,
      },
      dialogMessage: null,
      endServiceTaskId: null,
      endServicePatNum: null,
      endServiceModal: false,
      hasInstallationSheet: false,
      tasksToComplete: null,
      showSurveyForm: false,
    };
  },
  created() {
    this.today = getOnlyDate(new Date());
    this.userId = this.$store.state.auth.user.id;
  },
  beforeMount() {
    this.generateCalendar();
  },
  mounted() {
    this.calendarApi = this.$refs.fullCalendar.getApi();
  },
  methods: {
    generateCalendar() {
      const calendarGoTo = () => (this.calendarGoTo = true);
      this.calendarOptions = {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          bootstrapPlugin,
          listPlugin,
        ],
        locale: ptLocale,
        allDaySlot: false,
        selectable: true,
        firstDay: 1,
        height: 700,
        scrollTime: "09:00",
        slotDuration: "00:15",
        slotLabelInterval: "01:00",
        slotLabelFormat: {
          hour: "numeric",
          minute: "2-digit",
          omitZeroMinute: false,
          meridiem: "short",
        },
        initialView: "listDay",
        displayEventTime: true,
        displayEventEnd: true,
        editable: true,
        customButtons: {
          showCalendar: {
            text: "Ir para",
            click: function () {
              calendarGoTo();
            },
          },
        },
        titleFormat: {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        },
        headerToolbar: {
          left: "prev,today,next",
          center: "title",
          right: "listDay,listWeek,showCalendar",
        },
        buttonText: {
          listDay: "Por Dia",
          listWeek: "Por Semana",
        },
      };
      const getTasks = (dateStart, dateEnd) =>
        this.getUserTasks(dateStart, dateEnd);

      this.calendarOptions.events = function (info, successCallback) {
        getTasks(getOnlyDate(info.start), getOnlyDate(info.end)).then(
          (tasks) => {
            successCallback(tasks);
          }
        );
      };
    },
    getUserTasks(dateStart, dateEnd) {
      this.isLoading = true;
      this.removeAllTasks();
      this.totalWorkMinutes = 0;
      this.totalWorkHoursFormated = "00:00";
      let endInfo = moment(dateEnd);
      return taskService
        .getTasksAndBookings(this.userId, dateStart, dateEnd)
        .then((days) => {
          let userTasks = [];
          days.forEach((day) => {
            if (day.isHoliday || day.inVacation || day.missed) {
              let backgroundTask = {
                start: `${day.day} 00:00`,
                end: `${day.day} 23:59`,
                isHoliday: false,
                inVacation: false,
                missed: false,
                taskInfo: {
                  is_today: false,
                },
              };
              if (day.isHoliday) {
                backgroundTask.title = day.isHoliday;
                backgroundTask.isHoliday = true;
              } else if (day.inVacation) {
                backgroundTask.title = "";
                if (!day.inVacation.approved) {
                  backgroundTask.title = "(Não Aprovada)";
                }
                backgroundTask.inVacation = true;
                backgroundTask.color = "#33ff00";
              } else if (day.missed) {
                backgroundTask.title = day.missed;
                backgroundTask.missed = true;
                backgroundTask.color = "#ff0000";
              }
              userTasks.push(backgroundTask);
            }
            day.tasks.forEach((task) => {
              // task.typeId = this.getInterventionTypeId(task.type);
              if (task.isBooking != undefined && task.isBooking) {
                task["is_today"] = isToday(
                  new Date(`${task.date} ${task.start}`)
                );
                task["is_booking"] = true;
              } else {
                task["is_today"] = isToday(new Date(task.start));
                task["is_booking"] = false;
              }

              task["running"] = false;
              task["finished"] = false;
              if (task.is_today && !task.is_booking) {
                if (task.start_hour != "" && task.end_hour != "") {
                  task.finished = true;
                } else if (task.start_hour != "" && task.end_hour == "") {
                  task.running = true;
                  this.inWork = true;
                }
              }
              userTasks.push({
                id: task.id,
                title: task.is_booking
                  ? "Reserva"
                  : `${task.pat_number} - ${task.pat_title}`,
                start: task.is_booking
                  ? `${task.date} ${task.start}`
                  : task.start,
                end: task.is_booking ? `${task.date} ${task.end}` : task.end,
                color: hexToRGB("#FFFFFF", 1),
                backgroundColor: "#000000",
                editable: false,
                taskInfo: task,
                borderColor: "blue",
                isBooking: task.is_booking,
              });

              if (!task.is_booking) {
                let start = moment(task.start);
                if (endInfo.diff(start, "minutes") > 0) {
                  let end = moment(task.end);
                  this.totalWorkMinutes += end.diff(start, "minutes");
                }
              }
            });
          });

          this.totalWorkHoursFormated = formatMinutes(this.totalWorkMinutes);
          this.isLoading = false;
          return userTasks;
        });
    },
    removeAllTasks() {
      if (!this.calendarApi) {
        return;
      }
      let events = this.calendarApi.getEvents();
      events.forEach((event) => {
        event.remove();
      });
    },
    satisfactionsFormInfo(taskId) {
      this.showSatisfactionsFormInfo = true;
      this.$confirm.require({
        header: "Inquérito de Satisfação",
        message:
          "No fim do serviço preencher inquérito de satifação junto do cliente",
        icon: "pi pi-info-circle",
        acceptLabel: "Ok",
        acceptIcon: "pi pi-check",
        acceptClass: "p-button-text p-button-success p-button p-component",
        rejectLabel: "Fechar",
        rejectIcon: "pi pi-times",
        rejectClass: "p-button-text p-button-danger p-button p-component",
        accept: () => {
          var self = this;
          setTimeout(function () {
            self.confirmStartTask(taskId);
          }, 100);
        },
        reject: () => {
          var self = this;
          setTimeout(function () {
            self.confirmStartTask(taskId);
          }, 100);
        },
      });
    },
    confirmStartTask(taskId) {
      let task = this.calendarApi.getEventById(taskId);
      if (
        !this.showSatisfactionsFormInfo &&
        task.extendedProps.taskInfo.pat_number % 10 == 0
      ) {
        return this.satisfactionsFormInfo(taskId);
      }
      let message = `Pretende iniciar a deslocação para o serviço:
      ${task.extendedProps.taskInfo.pat_title} com o PAT nº
      ${task.extendedProps.taskInfo.pat_number}, agora?
    `;
      this.showSatisfactionsFormInfo = false;

      this.$confirm.require({
        header: "Iniciar deslocação",
        message: message,
        icon: "pi pi-question-circle",
        acceptLabel: "Sim",
        acceptIcon: "pi pi-check",
        acceptClass: "p-button-success p-button p-component",
        rejectLabel: "Não",
        rejectIcon: "pi pi-times",
        rejectClass: "p-button-danger p-button p-component",
        accept: () => {
          this.startTask(taskId);
        },
        reject: () => {
          this.$confirm.close();
        },
      });
    },
    startTask(taskId) {
      supportService.startSupport(taskId).then((response) => {
        if (!response) {
          return this.$toast.add({
            severity: "error",
            summary: "Erro ao iniciar o serviço",
            detail: "",
            life: 3000,
          });
        }

        let task = this.calendarApi.getEventById(taskId);
        let taskInfo = task._def.extendedProps.taskInfo;
        this.inWork = true;
        taskInfo.running = true;

        let auxStart = getOnlyDate(new Date(taskInfo.start));
        taskInfo.start = `${auxStart} ${response.new_start}`;
        taskInfo.start_hour = response.new_start;

        task.remove();
        this.calendarApi.addEvent({
          id: taskInfo.id,
          title: `${taskInfo.pat_number} - ${taskInfo.pat_title}`,
          start: taskInfo.start,
          end: taskInfo.end,
          color: hexToRGB("#FFFFFF", 1),
          backgroundColor: "#000000",
          editable: false,
          taskInfo: taskInfo,
          borderColor: "blue",
        });
        return this.$toast.add({
          severity: "success",
          summary: "Serviço iniciado com sucesso",
          detail: "",
          life: 3000,
        });
      });
    },
    confirmEndTask(taskId) {
      let task = this.calendarApi.getEventById(taskId);
      let message = `Pretende terminar a deslocação do serviço
      ${task.extendedProps.taskInfo.pat_title} com o PAT nº
      ${task.extendedProps.taskInfo.pat_number}?
    `;
      this.$confirm.require({
        header: "Terminar deslocação",
        message: message,
        icon: "pi pi-question-circle",
        acceptLabel: "Sim",
        acceptIcon: "pi pi-check",
        acceptClass: "p-button-success p-button p-component",
        rejectLabel: "Não",
        rejectIcon: "pi pi-times",
        rejectClass: "p-button-danger p-button p-component",
        accept: () => {
          this.service = task.extendedProps.taskInfo;
          if (
            this.service.pat_service_type == "Survey" &&
            this.userId == this.service.pat_technician_resp_user_id
          ) {
            return (this.showSurveyForm = true);
          }
          return (this.showServiceForm = true);
        },
      });
    },
    endTask() {
      let bodyParams = {
        resume: this.service.resume,
        description: this.service.description,
      };

      supportService
        .endSupport(this.service.id, bodyParams)
        .then((response) => {
          if (!response) {
            this.serviceFormClose();
            return this.$toast.add({
              severity: "error",
              summary: "Erro ao finalizar o serviço",
              detail: "",
              life: 3000,
            });
          }
          let task = this.calendarApi.getEventById(this.service.id);
          let taskInfo = task._def.extendedProps.taskInfo;
          this.inWork = false;
          let auxEnd = getOnlyDate(new Date(taskInfo.end));
          taskInfo.running = false;
          taskInfo.finished = true;
          taskInfo.resume = this.service.resume;
          taskInfo.description = this.service.description;
          taskInfo.end = `${auxEnd} ${response.new_end}`;
          taskInfo.end_hour = response.new_end;
          task.remove();
          this.calendarApi.addEvent({
            id: taskInfo.id,
            title: `${taskInfo.pat_number} - ${taskInfo.pat_title}`,
            start: taskInfo.start,
            end: taskInfo.end,
            color: hexToRGB("#FFFFFF", 1),
            backgroundColor: "#000000",
            editable: false,
            taskInfo: taskInfo,
            borderColor: "blue",
          });

          this.serviceFormClose();
          return this.$toast.add({
            severity: "success",
            summary: "Serviço finalizado com sucesso",
            detail: "",
            life: 3000,
          });
        });
    },
    serviceFormClose() {
      this.service = null;
      this.showServiceForm = false;
    },
    serviceFormSubmited() {
      this.endTask();
    },
    goDate(date) {
      this.calendarApi.gotoDate(date.id);
      this.calendarGoTo = false;
    },
    showTaskInfo(taskId) {
      let task = this.calendarApi.getEventById(taskId);
      let taskInfo = task._def.extendedProps.taskInfo;

      this.moreInfo.entity = taskInfo.entity_name;
      this.moreInfo.patNunber = taskInfo.pat_number;
      this.moreInfo.patTitle = taskInfo.pat_title;
      this.moreInfo.patNote = taskInfo.note;
      this.moreInfo.patProblem = taskInfo.pat_problem;
      this.moreInfo.address = taskInfo.localization.address;
      this.moreInfo.zipCode = taskInfo.localization.zip_code;
      this.moreInfo.local = taskInfo.localization.local;
      this.moreInfo.hasInstallationSheet = taskInfo.has_installation_sheet;
      this.moreInfo.taskId = taskInfo.id;
      if (taskInfo.pat_service_type != "") {
        this.moreInfo.patServiceType = taskInfo.pat_service_type;
      }
      this.showInfo = true;
    },
    hideAdrress() {
      this.showInfo = false;
      this.moreInfo.entity = null;
      this.moreInfo.patNunber = null;
      this.moreInfo.patTitle = null;
      this.moreInfo.patNote = null;
      this.moreInfo.address = null;
      this.moreInfo.zipCode = null;
      this.moreInfo.local = null;
      this.moreInfo.hasInstallationSheet = null;
      this.moreInfo.taskId = null;
      this.moreInfo.patProblem = null;
      this.moreInfo.patServiceType = null;
    },
    goToAdrress() {
      window.open(
        `http://maps.google.com/?q=${this.moreInfo.address}, ${this.moreInfo.zipCode}, ${this.moreInfo.local}`,
        "_blank"
      );
    },
    goToInstallationSheet() {
      let route = this.$router.resolve({
        path: `/task-installation-sheet/${
          this.moreInfo.taskId != undefined
            ? this.moreInfo.taskId
            : this.endServiceTaskId
        }`,
      });
      window.open(route.href, "_blank");
    },
    confirmStartService(taskId) {
      let task = this.calendarApi.getEventById(taskId);
      let message = `Pretende iniciar este serviço,
      ${task.extendedProps.taskInfo.pat_title} com o PAT nº
      ${task.extendedProps.taskInfo.pat_number}, nas instalações do cliente agora?
    `;
      this.$confirm.require({
        header: "Iniciar serviço - Instalação do cliente",
        message: message,
        icon: "pi pi-question-circle",
        acceptLabel: "Sim",
        acceptIcon: "pi pi-check",
        acceptClass: "p-button-success p-button p-component",
        rejectLabel: "Não",
        rejectIcon: "pi pi-times",
        rejectClass: "p-button-danger p-button p-component",
        accept: () => {
          this.startService(taskId);
        },
      });
    },
    startService(taskId) {
      supportService.startSupportService(taskId).then((response) => {
        if (!response) {
          return this.$toast.add({
            severity: "error",
            summary: "Erro ao iniciar o serviço",
            detail: "",
            life: 3000,
          });
        }

        let task = this.calendarApi.getEventById(taskId);
        let taskInfo = task._def.extendedProps.taskInfo;

        taskInfo.service_start = response.service_start;

        task.remove();
        this.calendarApi.addEvent({
          id: taskInfo.id,
          title: `${taskInfo.pat_number} - ${taskInfo.pat_title}`,
          start: taskInfo.start,
          end: taskInfo.end,
          color: hexToRGB("#FFFFFF", 1),
          backgroundColor: "#000000",
          editable: false,
          taskInfo: taskInfo,
          borderColor: "blue",
        });
        return this.$toast.add({
          severity: "success",
          summary: "Serviço iniciado com sucesso",
          detail: "",
          life: 3000,
        });
      });
    },
    confirmEndService(taskId) {
      let task = this.calendarApi.getEventById(taskId);
      this.endServiceTaskId = taskId;
      this.dialogMessage = `Pretende terminar este serviço,
      ${task.extendedProps.taskInfo.pat_title} com o PAT nº
      ${task.extendedProps.taskInfo.pat_number}, nas instalações do cliente agora?
    `;
      this.endServicePatNum = task.extendedProps.taskInfo.pat_number;
      this.hasInstallationSheet =
        task.extendedProps.taskInfo.has_installation_sheet;
      this.endServiceModal = true;
    },
    endService(taskId) {
      supportService.endSupportService(taskId).then((response) => {
        if (!response) {
          return this.$toast.add({
            severity: "error",
            summary: "Erro ao iniciar o serviço",
            detail: "",
            life: 3000,
          });
        }

        let task = this.calendarApi.getEventById(taskId);
        let taskInfo = task._def.extendedProps.taskInfo;

        this.closeModalEndService();

        taskInfo.service_end = response.service_end;

        task.remove();
        this.calendarApi.addEvent({
          id: taskInfo.id,
          title: `${taskInfo.pat_number} - ${taskInfo.pat_title}`,
          start: taskInfo.start,
          end: taskInfo.end,
          color: hexToRGB("#FFFFFF", 1),
          backgroundColor: "#000000",
          editable: false,
          taskInfo: taskInfo,
          borderColor: "blue",
        });
        return this.$toast.add({
          severity: "success",
          summary: "Serviço iniciado com sucesso",
          detail: "",
          life: 3000,
        });
      });
    },
    goSatisfactionForm() {
      this.$router.push(
        `/satisfaction-form-support-quiz/${this.endServicePatNum}`
      );
    },
    closeModalEndService() {
      this.endServiceTaskId = null;
      this.endServiceModal = false;
      this.dialogMessage = null;
      this.endServicePatNum = null;
      this.hasInstallationSheet = false;
    },
    viewPatInfo(pat) {
      let route = this.$router.resolve({
        path: `/pat-details/${pat}`,
      });
      window.open(route.href, "_blank");
    },
    tasksToCompleteChanged(tasksToComplete) {
      this.tasksToComplete = tasksToComplete;
    },
    surveyFormClose() {
      this.service = null;
      this.showSurveyForm = false;
    },
    surveyFormSubmited() {
      this.showSurveyForm = false;
      this.showServiceForm = true;
    },
  },
};
</script>

<style>
.fc-event-main {
  background: rgba(255, 255, 255, 0) !important;
  background-color: rgba(255, 255, 255, 0) !important;
  border: 0px !important;
}

.fc-scrollgrid-shrink,
.fc-scrollgrid-shrink + td {
  border-top-width: 3px !important;
}

/* .fc .fc-timegrid-col.fc-day-today {
   background: rgb(0, 0, 255) !important;
}  */

.fc .fc-list-event td {
  background: #f1f1f1;
}

.fc .fc-list-day th {
  background: #ff4910 !important;
  color: #fff;
  z-index: 500;
}

.fc .fc-list-event-graphic {
  display: none !important;
}

.fc .fc-list-event-time {
  white-space: unset !important;
  word-wrap: break-word !important;
  min-width: 60px !important;
  width: auto !important;
}
.fc .fc-list-table td,
.fc .fc-list-day-cushion {
  padding: 5px 0px 0px 2px !important;
}

.stop {
  width: 10px;
  height: 10px;
  background-color: #fff;
}

.play {
  font-size: 22px;
  margin-left: 3px;
  margin-bottom: 1px;
}

.p-confirm-dialog {
  z-index: 90000 !important;
}
</style>
